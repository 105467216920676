<template>
  <b-row class="h-100 py-5">
      
      <b-col cols="1"></b-col>
      <b-col class="mt-5" cols="10">
        <h2 class="text-center">Projects</h2>
        <b-row class="justify-content-center">
        <b-button :class="frontend ? 'view-project' : 'btn_inactive'" @click="frontend = true" class="stack_btn">Frontend</b-button>
        <b-button :class="!frontend ? 'view-project' : 'btn_inactive'" @click="frontend = false" class="stack_btn ms-3">Full Stack</b-button>
        </b-row>
        <div v-if="frontend">
        <b-row class="mt-5">
          <b-col cols="12" lg="6" class="project-container mb-4 mb-lg-0">
            <b-card class="bg-dark rounded-xl d-flex flex-column">
              <b-card-img class="object-fit-contain" :src="require('../assets/invown.png')"/>

              <div class="mt-3 project-title">Invown</div>
              <p class="mt-2">
              A marketplace where companies offer real estate-backed investment opportunities to investors
            </p>

            <b-row class="d-flex mx-auto">
              <b-col cols="auto" class="text-center mt-2 mt-lg-0 h-100 py-1 used-tech">Vue</b-col>
              <b-col cols="auto"  class="text-center mt-2 mt-lg-0 h-100 py-1 used-tech">Bootstrap</b-col>
              <b-col cols="auto"  class="text-center mt-2 mt-lg-0 h-100 py-1 used-tech">scss</b-col>
              <b-col cols="auto">
                <b-button href="https://www.invown.com/app/properties/browse-all" target="_blank" class="view-project mt-2 mt-lg-0 border-0">View Project</b-button>
              </b-col>
            </b-row>
            </b-card>
            </b-col>

          <b-col class="project-container">
            <b-card class="bg-dark rounded-xl d-flex flex-column">
              <b-card-img class="object-fit-contain" :src="require('../assets/asher.png')"/>

              <div class="mt-3 project-title">Web Design Studio</div>
              <p class="mt-2">
              Landing page for Asher Web Design Studio
            </p>

            <b-row class="d-flex mx-auto">
              <b-col cols="auto" class="text-center mt-2 mt-lg-0 h-100 py-1 used-tech">HTML</b-col>
              <b-col cols="auto"  class="text-center mt-2 mt-lg-0 h-100 py-1 used-tech">CSS</b-col>
              <b-col cols="auto">
                <b-button href="https://web-design-studio.vercel.app/" target="_blank" class="view-project mt-2 mt-lg-0 border-0">View Project</b-button>
              </b-col>
            </b-row>
            </b-card>
            </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col cols="12" lg="6" class="project-container mb-4 mb-lg-0">
            <b-card class="bg-dark rounded-xl">
              <b-card-img :src="require('../assets/tryglass.png')"/>
              <div class="mt-3 project-title">Glass</div>
            <p class="mt-2">
              Landing page for Glass browser extension
            </p>
            <b-row class="d-flex mx-auto">
              <b-col cols="auto" class="text-center mt-2 mt-lg-0 h-100 py-1 used-tech">Vue</b-col>
              <b-col cols="auto"  class="text-center mt-2 mt-lg-0 h-100 py-1 used-tech">Tailwind</b-col>
              <b-col cols="auto">
                <b-button href="https://try.glass/" target="_blank" class="view-project mt-2 mt-lg-0 border-0">View Project</b-button>
              </b-col>
            </b-row>
            </b-card>
            
            </b-col>

          <b-col class="project-container">
            <b-card class="bg-dark rounded-xl">
              <b-card-img :src="require('../assets/invoice.png')"/>
              <div class="mt-3 project-title">Invoice App</div>
            <p class="mt-2">
              Frontend mentor challenge
            </p>
            <b-row class="d-flex mx-auto">
              <b-col cols="auto" class="text-center mt-2 mt-lg-0 h-100 py-1 used-tech">Vue</b-col>
              <b-col cols="auto" class="text-center mt-2 mt-lg-0 h-100 py-1 used-tech">scss</b-col>
              <b-col cols="auto"  class="text-center mt-2 mt-lg-0 h-100 py-1 used-tech">Firebase</b-col>
              <b-col cols="auto">
                <b-button href="https://invoice-app-e4c26.web.app/" target="_blank" class="view-project mt-2 mt-lg-0 border-0">View Project</b-button>
              </b-col>
            </b-row>
            </b-card>
            
            </b-col>
        </b-row>
</div>
<div v-else>
<b-row class="mt-5">
  <b-col cols="12" lg="6" class="project-container">
            <b-card class="bg-dark rounded-xl">
              <b-card-img :src="require('../assets/todoApp.png')"/>
              <div class="mt-3 project-title">Todo App</div>
            <p class="mt-2">
              Frontend mentor challenge
            </p>
            <b-row class="d-flex mx-auto">
              <b-col cols="auto" class="text-center mt-2 mt-lg-0 h-100 py-1 used-tech">React</b-col>
              <b-col cols="auto" class="text-center mt-2 mt-lg-0 h-100 py-1 used-tech">Python</b-col>
              <b-col cols="auto"  class="text-center mt-2 mt-lg-0 h-100 py-1 used-tech">Flask</b-col>
              <b-col cols="auto"  class="text-center mt-2 mt-lg-0 h-100 py-1 used-tech">Postgresql</b-col>
              <b-col cols="auto">
                <b-button href="https://todo-react-flask-app.herokuapp.com/" target="_blank" class="view-project mt-2 mt-lg-0 border-0">View Project</b-button>
              </b-col>
            </b-row>
            </b-card>
            
            </b-col>
            </b-row>
</div>
      </b-col>
      
      <b-col cols="1"></b-col>
      
  </b-row>
</template>

<script>
export default {
  data: () => {
    return {
      frontend: true
    }
  }
}
</script>

<style>
.bg-dark-gray {
  background-color: #1C1E21 !important;
}

.rounded-xl {
  border-radius: 12px !important;
}
.stack_btn {
  width: 25vmin !important;
}

.project-container p {
  font-size: 14px !important;
}
.project-container img {
  max-height: 387px !important;
}
.project-title {
  font-size: 20px !important;
}

.used-tech {
  border: 1px solid white !important;
  margin-right: 8px;
}

.view-project, .view-project:focus {
  background-color: #575DFB !important;
  border: none !important;
  outline: none !important;
}

.btn_inactive, .btn_inactive:focus {
  border-color: #575DFB !important;
  background: transparent !important;
  outline: #575DFB !important;
}
</style>