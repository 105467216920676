<template>
  <div id="app">
    <Navbar :class="isSticky ? 'is_sticky' : 'd-none'"/>
    <Header class="vh-100" />
    <Results class="bg-dark-gray results"/>
    <Testimonials class="testimonials"/>
    <Services id="services" class="h-100 bg-dark-gray" />
    <Toolbox class="h-100" />
    <Projects id="projects" class="h-100 bg-dark-gray" />
    <Contact id="contact" />
    <footer class="bg-dark-gray mt-5 d-flex justify-content-center align-items-center footer px-3">
      <div>This site was built using Vue, Bootstrap and icons from 
      <a class="text-white text-decoration-none" href="https://icons8.com/">icons8</a></div>
      </footer>  
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Navbar from "./components/Navbar.vue";
import Results from "./components/Results.vue";
import Testimonials from "./components/Testimonials.vue";
import Services from "./components/Services.vue";
import Toolbox from '@/components/Toolbox.vue';
import Projects from "./components/Projects.vue";
import Contact from "./components/Contact.vue";

export default {
  name: "App",
  components: {
    Header,
    Navbar,
    Results,
    Testimonials,
    Services,
    Toolbox,
    Projects,
    Contact
  },
  data: () => {
    return {
      isSticky: false,
      scrollPosition: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= 500) {
        this.$set(this, "isSticky", true);
      } else {
        this.$set(this, "isSticky", false);
      }
    },
  }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Gugi&family=Open+Sans&family=Oxanium:wght@700&display=swap");

#app {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  background-color: #131416;
  color: #edfdee;
  overflow: hidden !important;

}

h1 {
  font-family: "Gugi", Helvetica, Arial, sans-serif;
}

h2,
h3 {
  font-family: "Oxanium", Helvetica, Arial;
  font-weight: 700;
}

::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #131416 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #575DFB !important;
  border-radius: 10px !important;
}

.bg-dark-gray {
  background-color: #1C1E21;
}

.font-weight-bold {
  font-weight: bold;
}

.rounded-xl {
  border-radius: 12px;
}

.results {
  min-height: 60vh !important;
}

.footer {
  height: 20vh !important;
}

.testimonials {
  min-height: 60vh !important;
}

.is_sticky {
        position: fixed !important;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030 !important;
        width: 100%;
        animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1) !important;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
</style>
