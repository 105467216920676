<template>
  <b-row class="my-5 mb-lg-0 mt-lg-5 pt-lg-5">
      <b-col cols="1" lg="1"></b-col>
      <b-col cols="10" lg="10">
          <h2 class="text-center mb-0">Testimonials</h2>

          <b-row class="align-items-center h-75 my-4">
              <b-col class="mb-3" v-for="testimonial,i in testimonials" :key="i" cols="12" lg="4">
                  <b-card class="review-card p-3 rounded-xl d-flex my-3 my-lg-0 flex-column align-items-center">
                      <p>{{testimonial.review}}</p>
                      <span class="">- {{ testimonial.name }}</span>
                  </b-card>
              </b-col>
          </b-row>
      </b-col>
      <b-col cols="1" lg="1"></b-col>
  </b-row>
</template>

<script>
export default {
    data: () => {
        return {
            testimonials: [
                {
                 review: '"Jacob executed on the job with care and attention. I particularly enjoyed communicating with Jacob, as he always informed me of what’s up and set clear expectations. Would highly recommend!"',
                 name: 'Sasha'
                },
                {
                 review: '"Jacob is such a nice person to work with. Great communications. Positive attitude. Quick learner. Professional services. Highly recommend. A+++"',
                 name: 'Asher'
                },
                {
                 review: '"Jacob is a highly proactive, detail oriented consultant with deep technical knowledge and a friendly attitude. Highly recommended and will rehire him for larger projects."',
                 name: 'Ben'
                },
                {
                 review: '"Jacob was super effective and fast. He got familiar with a complex project very quickly and got straight to the point. We had a major change in the requirements during his work and he proved very understanding adapted quickly. Easy & pleasant communication, attention to details and to our specific requirements."',
                 name: 'Thomas'
                }
            ],
        }
    }
}
</script>

<style>
.review-card {
    background-color: #1C1E21 !important;
    min-height: 250px !important;
}

.review-card p {
    font-style: italic !important;
    min-height: 142px !important;
}
</style>