<template>
  <b-row class="align-items-center">
      <b-col cols="1" lg="3"></b-col>
      <b-col class="text-center p-wrapper" cols="10" lg="6">
          <h2 class="text-capitalize mb-4 mt-5 mt-lg-0">I get you results</h2>
          <p>I build sites and apps with fast load-times <br> that do one thing: <span class="get-you-results">Get You Results.</span>

</p>
          <p>I'm here to help you- with a goal oriented eye for precision, <br>I will build your app according to your needs

</p>
          <p>When we're done, you will have a site/app that<br> gets the job done.

</p>

<b-button href="#projects" class="mt-5 past-work-btn p-3 mb-5 mb-lg-0">SEE PAST WORK</b-button>
      </b-col>
      <b-col cols="1" lg="3"></b-col>
  </b-row>
</template>

<script>
export default {

}
</script>

<style>
h2 {
    font-size: 60px !important;
}
.get-you-results {
    color: #575DFB;
    font-weight: 700;
}

.p-wrapper p {
    line-height: 28px;
    font-size: 20px;
}

.past-work-btn {
    background: transparent !important;
    border: 1px solid #575DFB !important;
    font-size: 16px !important;
}
.past-work-btn:hover {
    background: #575DFB !important;
    color: white !important;
}

</style>