<template>
  <b-container fluid>
    <header class="position-relative">
      <b-row cols="12" class="justify-content-center align-items-center vh-100 mx-0 px-0">
        <b-col cols="1" />
        <b-col
          cols="12"
          lg="4"
          xl="3"
        >
          <h1 class="mb-lg-4">Freelance <br> Developer</h1>

          <p>Helping people flip their thoughts<br> into
            sites &amp; apps that work. <br>
            Professional and Cost-Effective
            <br>Always.
          </p>
          <b-row class="pt-3">
            <b-col cols="8">
              <b-button href="#contact" class="orange w-100 py-3 border-0 rounded-xl font-weight-bold primary-cta">
                HIRE ME
              </b-button>

              <b-button href="#services" class="w-100 py-3 mt-3 rounded-xl secondary-cta">
                VIEW SERVICES
              </b-button>
            </b-col>
            <b-col class="d-flex flex-column justify-content-between">
              <b-col class="d-flex align-items-center">
                <a href="https://github.com/DLN1" target="_blank"><b-icon-github height="25" width="25"/></a>
              </b-col>
              <b-col class="d-flex align-items-center">
                <a href="https://www.linkedin.com/in/jacob-carlberg-048685195/" target="_blank"><b-icon-linkedin height="25" width="25" /></a>
              </b-col>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="0"
          lg="4"
          class="d-none d-lg-flex"
        ><img
            src="../assets/header-img.svg"
            alt="JCdev header image"
            height="500"
            width="auto"
          ></b-col>
        <b-col cols="0" lg="3" />
      </b-row>
    </header>
  </b-container>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>


<style scoped>
h1 {
  font-size: 60px;
}

p {
  font-size: 20px;
}
a {
  color: white;
}

a:hover {
  color: rgb(167, 167, 167) !important;
}
.orange {
  background-color: #575DFB !important;
}

.primary-cta {
  border-radius: 12px !important;
  font-weight: bold !important;
}

.secondary-cta {
  background-color: transparent !important;
  border-radius: 12px !important;
  font-weight: bold !important;
  color: #575DFB !important;
  border: 1px solid #575DFB !important;
}
</style>
