<template>
  <b-navbar
    class="d-flex justify-content-between align-items-center navbar py-3 px-4"
  >
    <b-navbar-brand
      class="text-white d-flex align-items-center"
      href="#"
    >
      <img
        class="profile-picture"
        src="../assets/jcDev.png"
        alt="JCdev profile picture"
      />
    </b-navbar-brand>

    <b-navbar-nav class="ml-auto">
      <b-button
      href="#contact"
        size="sm"
        class="my-2 my-sm-0 text-uppercase border-0 rounded-xl orange px-3 py-2"
        type="submit"
      >hire me</b-button>
    </b-navbar-nav>

  </b-navbar>
</template>

<script>
export default {
  

  methods: {
    
  },
};
</script>

<style>
.rounded-xl {
  border-radius: 10px !important;
}

.orange {
  background-color: #575dfb !important;
}

.profile-picture {
  margin-right: 10px;
}

.navbar {
  background-color: #1C1E21 !important;
}

</style>