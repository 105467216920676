<template>
  <b-row class="h-100 align-items-center">
      <b-col cols="1" lg="2"></b-col>
      <b-col cols="10" lg="8">
          <h2 class="text-center mt-5">My Toolbox</h2>

        <b-row class="mt-5 pb-5">
            <h3 class="text-center mb-5">Frontend</h3>
            <b-col  v-for="item, idx in frontend" :key="idx">
                <b-card style="width: 120px !important;" v-if="item" class="bg-dark-gray d-flex my-2 my-xl-0 flex-column align-items-center justify-content-center">
                    <b-card-img height="35" width="35" v-if="item && item.icon" :src="require('../assets/' + item.icon)" />
                    
                    <p class="mb-0 mt-3 font-weight-bold text-center">{{item.name}}</p>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="mt-5 pb-5">
            <h3 class="text-center mb-5">Backend</h3>
            <b-col v-for="item, idx in backend" :key="idx" class="d-flex flex-column align-items-center">
                <b-card style="width: 135px !important;" class="bg-dark-gray d-flex flex-column align-items-center justify-content-center">
                    <b-card-img height="48" width="48" :src="require('../assets/' + item.icon)"/>

                    <p class="mb-0 mt-3 font-weight-bold">{{ item.name }}</p>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="mt-5 mb-5">
            <h3 class="text-center mb-5">General</h3>
            <b-col v-for="item, idx in general" :key="idx">
                <b-card style="width: 120px !important;" v-if="item" class="bg-dark-gray d-flex my-2 my-xl-0 flex-column align-items-center justify-content-center">
                    <b-card-img height="35" width="35" v-if="item && item.icon" :src="require('../assets/' + item.icon)" />
                    
                    <p class="mb-0 mt-3 font-weight-bold text-center">{{item.name}}</p>
                </b-card>
            </b-col>
        </b-row>

      </b-col>
      <b-col cols="1" lg="2"></b-col>
  </b-row>
</template>

<script>
export default {
    data: () => {
        return {
            frontend: [
                {
                    name: 'HTML',
                    icon: 'html.svg'
                },
                {
                    name: 'CSS',
                    icon: 'css.svg'
                },
                {
                    name: 'Bootstrap',
                    icon: 'bootstrap.svg'
                },
                {
                    name: 'Flutter',
                    icon: 'flutter-50.svg'
                },
                {
                    name: 'Javascript',
                    icon: 'javascript.svg'
                },
                {
                    name: 'Vue.js',
                    icon: 'vue.svg'
                },
            ],
            backend: [
                {
                    name: 'Firebase',
                    icon: 'firebase.svg'
                },
                {
                    name: 'Node.js',
                    icon: 'node.png'
                },
                {
                    name: 'mongoDB',
                    icon: 'mongo.png'
                }
            ],
            general: [
                {
                    name: 'Github',
                    icon: 'github.svg'
                },
                {
                    name: 'git',
                    icon: 'git.svg'
                },
                {
                    name: 'Figma',
                    icon: 'figma.svg'
                },
                
                {
                    name: 'WordPress',
                    icon: 'wordpress.svg'
                },
            ]
        }
    }
}
</script>

<style>
.bg-dark-gray {
  background-color: #1C1E21 !important;
}
</style>