<template>
  <div class="help-container">
    <h2 class="text-center">How I can help you</h2>
    <b-row class="mt-5">
      <b-col
        cols="1"
        lg="2"
      ></b-col>

      <b-col
        cols="10"
        lg="8"
      >
        <b-row class="d-flex">
          <b-col
            class="d-flex flex-column justify-content-center order-lg-first order-2 mt-5 mt-lg-0"
            cols="12"
            lg="6"
          >
            <h3>1. Own A Website/app That Works</h3>
            <p class="mt-4 ">
              You want a website/app. It has to look great and have all the features you need, and work every time.
              Well, thats why I'm here! I help you building and hosting your site that will grow your business with no hassle
            </p>
          </b-col>
          <b-col
            class="ps-lg-5"
            cols="12"
            lg="6"
          >
            <img
              src="../assets/website.svg"
              style="height: 100%; width: 100%; max-width: 500px; max-height: 400px;"
            />
          </b-col>
        </b-row>

        <b-row class="d-flex mt-5 pt-5">
          <b-col
            class="pe-lg-5"
            cols="12"
            lg="6"
          >
            <img
              src="../assets/features.svg"
              style="height: 100%; width: 100%; max-width: 500px; max-height: 400px;"
            />
          </b-col>
          <b-col
            class="mt-5 mt-lg-0 d-flex flex-column justify-content-center"
            cols="12"
            lg="6"
          >
            <h3>2. Control The Features</h3>
            <p class="mt-4 ">
              I provide a custom code solution for building your website. You get to choose what it's made from and how you want it, which will result in an optimized site that performs well on all devices. Wouldn't you rather have something perfect?

            </p>
          </b-col>
        </b-row>

        <b-row class="d-flex mt-5 pt-5">
          <b-col
            class="d-flex flex-column justify-content-center order-lg-first order-2 mt-5 mt-lg-0"
            cols="12"
            lg="6"
          >
            <h3>3. App Development</h3>
            <p class="mt-4 ">
              I will develop your app with modern cross-platform technology that makes your app look, and work, in the same way on both iPhone and Android.
            </p>
          </b-col>
          <b-col
            class="ps-lg-5"
            cols="12"
            lg="6"
          >
            <img
              src="../assets/flutter.svg"
              style="height: 100%; width: 100%; max-width: 500px; max-height: 400px;"
            />
          </b-col>
        </b-row>
      </b-col>

      <b-col
        cols="1"
        lg="2"
      ></b-col>
    </b-row>
  </div>
</template>

<script>
export default {};
</script>

<style>
.help-container {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

h3 {
  font-size: 24px !important;
}

.website p {
  line-height: 25px;
}
</style>