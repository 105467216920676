<template>
  <b-row>
      <b-col cols="1" lg="4"></b-col>
      <b-col cols="10" lg="4">
        <h2 class="text-center mt-5">Contact</h2>
          <b-form method="POST" action="https://formsubmit.co/c928457584a6c4cf8972de3300014aae" class="contact-form d-flex flex-column p-5">
              <b-input-group class="d-flex flex-column">
                <label class="mb-2">Name*</label>
              <b-input required name="name" class="contact-input w-100"/>
              </b-input-group>
              <b-input-group class="d-flex flex-column mt-4">
                <label class="mb-2">Email*</label>
              <b-input required name="email" class="contact-input w-100"/>
              </b-input-group>
              <b-input-group class="d-flex flex-column mt-4">
                <label class="mb-2">Message*</label>
              <b-textarea required name="message" class="contact-input textarea w-100"/>
              </b-input-group>
              <b-button type="submit" class="mt-4 contact-btn border-0 py-2">
                  <template v-if="!message_sent">
                     Send Message
                  </template>
                  <template v-else>Message Sent</template>
              </b-button>
          </b-form>
      </b-col>
      <b-col cols="1" lg="4"></b-col>
  </b-row>
</template>

<script>
export default {
    data: () => {
        return {
            name: "",
            email: "",
            message: "",
            message_sent: false
        }
    },
    methods: {
        submit() {
            console.log("sent")
        }
    }
}
</script>

<style>
.contact-form {
    background-color: #1C1E21 !important;
    border-radius: 12px !important;
}

.contact-input {
   background-color: #1C1E21 !important;
   border-radius: 12px !important;
   color: white !important;
}

.textarea {
    height: 250px !important;
}

.contact-btn {
    background: #575DFB !important;
}
</style>